import { mainPage } from "./src/mainPage/MainPage";
import { MainPageContainerContent } from "./src/types/mainPageT";
 

(() => {
    const mPage: MainPageContainerContent = mainPage.ids();
    
    const instructionsRow = document.getElementById(mPage.instructionsPart) as HTMLDivElement;
    
    // Обработчик перехода на другую страницу по нажатию на область блока раздела инструкций
    instructionsRow.addEventListener('click', (e: Event) => {
        const idClickElement = (<HTMLElement>e.target).parentElement!.id;
        for (const mpage of mPage.instructions.thermoControllers) {
            if (mpage.id === idClickElement) {
                window.location.href = mpage.url
            }
        }   
    });

})(); 

// ----------- *** ---------

    


