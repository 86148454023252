import { MainPageContainerContent } from "../types/mainPageT";

export const mainPage = (() => {
    const ids = (): MainPageContainerContent => {
        return {
            contentRow: 'mainPageContainerContent',
            instructionsPart: 'instructions',
            instructions: {
                thermoControllers: [
                    {
                        id: 'sensors',
                        url: '/thermoControllersSettings' 
                    },
                    {
                        id: 'engishLearn',
                        url: '/english/index'
                    }
                ]
            }
        };
    };
    return { ids, };
})();